import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosWrapper from './axiosWrapper';
import GetBasicDetails from '../../core/utils/GetBasicDetails';
import GetCookieDataObject from '../../core/utils/GetCookieDataObject';
import { featureFlags } from '../../core/FeatureFlags/FeatureFlags';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';

export type PostDataType = {
  email: string;
  firstName: string;
  lastName: string;
  referralCodeOrName: string;
  locale: string;
};

const { orgCodeID, orgCode, leadSource, accountType, parentOrganizationCode, channel } = GetBasicDetails();

const addEnquiryLead = createAsyncThunk('registration-service/addEnquiryLead', async (data: PostDataType, thunkAPI) => {
  // Hard-coding the IP to London, UK until the changes for POP-8106 are done
  // const IPData: string = '103.204.124.0';

  const { firstName, lastName, email, referralCodeOrName, locale } = data;
  const {
    cookiesData,
    ppcData,
    referralCookieData,
    isCookiesDataEmpty,
    isPPCDataEmpty,
    isReferralCookieDataApplicable
  } = GetCookieDataObject('addEnquiryLead');
  const {
    ppcData: { allowPPCCookies }
  } = featureFlags;

  const payload = {
    accountType: accountType,
    firstName: firstName,
    lastName: lastName,
    organizationID: orgCodeID,
    source: leadSource,
    locale: locale,
    orgCode: orgCode,
    parentOrgCode: parentOrganizationCode,
    // externalIP: IPData,
    eMail: email,
    referredByText: referralCodeOrName,
    ...(!isCookiesDataEmpty && { cookiesData: cookiesData }),
    ...(allowPPCCookies && !isPPCDataEmpty && { ppcData: ppcData }),
    ...(isReferralCookieDataApplicable && { referralCookieData: referralCookieData }),
    ...(channel && {channel})
  };

  try {
    const response = await axiosWrapper('addEnquiryLead', 'POST', thunkAPI, payload);

    return response && response.data;
  } catch (error: any) {
    thunkAPI.dispatch(getServicesErrorObj(error));
    return thunkAPI.rejectWithValue({ error: error.response.data.message });
  }
});

export default addEnquiryLead;
