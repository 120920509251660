import { SupportedLanguages } from 'onfido-sdk-ui';
import { CookieAttributes } from 'js-cookie';

import { CountryCodeWithAdditionalFields } from '../../types/CountryDataTypes';
import { CountryCodesWithStateDropdown } from '../../types/CountryStateTypes';
import { HexColour } from '../../types/ThemeConfigTypes';
import { CustomErrorTypes } from '../../types/RegistrationStepForm';
import { OrganizationDomainCodeConfigType, OrganizationCodeTypes } from '../../types/OrganizationTypes';
import { CookieDataObjectType } from '../../types/CookieDataObjectTypes';
import { FooterLinkConfigType } from '../../../components/organisms/RPFooter/RPFooter';
import { LiveChatConfig, RouteLiveChatConfigType } from '../../types/LiveChatTypes';
import {
  InformationalBlurbContent,
  InformationalBlurbContentType
} from '../../../components/molecules/RPInformationalBlurb/RPInformationalBlurb';
import { EtvCurrencyObjType, PropertyPayCurrenciesTypes } from '../../types/PropertyPayCurrenciesTypes';

export const CURRENCIES_DIRECT: string = 'Currencies Direct';
export const TORFX: string = 'TorFX';
export const FCG: string = 'FCG';
export const TORFX_OZ: string = 'TorFXOz';
export const TENANTS: { [key: string]: OrganizationCodeTypes } = {
  CURRENCIES_DIRECT: 'currenciesdirect',
  TOR_FX: 'torfx',
  TORFX_OZ: 'torfxoz',
  FCG: 'fcg'
};
export const ORGANIZATION_CURRENCY_MAPPING = {
  currenciesdirect: 'GBP',
  torfx: 'GBP',
  torfxoz: 'AUD',
  fcg: 'GBP'
};
export const LANGUAGE_DROPDOWN_DATA = [
  { label: 'EN', value: 'en-gb' },
  { label: 'FR', value: 'fr-fr' },
  { label: 'US', value: 'en-us' }
];
export const TEXT_OR_CONTAINER_REQUIRED: string = 'Text or Container Required';
export const ADDRESS: string = 'Address';
export const PCA_API_KEY: string = 'RZ21-CD99-ZF18-CG63';
export const DEFAULT_COUNTRY: string = 'gbr';
export const MAX_ATTEMPTS: number = 10;
export const POLL_INTERVAL: number = 10 * 1000;
export const COMPLIANCE_VALID_CODE: string[] = ['918', '919', '908', '909', '910'];
export const CD_SA_URL: string = 'https://cdsa.currenciesdirect.com/';
export const STEPS_ROUTE_MAPPING: { [key: string]: string } = {
  'create-login': '/registration/account',
  'setup-security': '/registration/set-up-security',
  'enter-details': '/registration/your-details',
  'set-preferences': '/registration/set-preference',
  identity: '/registration/identity',
  'confirming-you': '/registration/document-upload'
};
export const CD_TNC_LINK: string = 'https://www.currenciesdirect.com/en/info/terms-of-use';
export const CD_PRIVACY_POLICY_LINK: string = 'https://www.currenciesdirect.com/en/info/privacy-policy';
export const CD_COOKIES_LINK: string = 'https://www.currenciesdirect.com/en/info/cookie-policy';
export const CD_REGULATORY_INFORMATION_LINK: string = 'https://www.currenciesdirect.com/en/info/regulatory-information';
export const CD_TNC_EN_US_LINK: string = 'https://www.currenciesdirect.com/terms_usa';
export const CD_PRIVACY_POLICY_EN_US_LINK: string = 'https://www.currenciesdirect.com/privacy_usa';
export const COUNTRY_CODE_USA: string = '+1*USA';
export const EN_US: string = 'en-us';
export const LANG_CODE_MAPPING: { [keyName: string]: string } = {
  EN: 'en-gb',
  FR: 'fr-fr',
  NO: 'no-no',
  SE: 'se-se',
  US: 'en-us'
};
export const LOCALE_LANG_CODE_MAPPING: { [keyName: string]: string } = {
  'en-gb': 'en',
  'fr-fr': 'fr',
  'no-no': 'no',
  'se-se': 'se',
  'en-us': 'us'
};
export const ORG_LANG_MAPPING: { [key: string]: Array<string> } = {
  cd: ['EN', 'US', 'FR', 'NO', 'SE'],
  torfx: []
};
export const USA_COR_ETV_DEFAULT_VALUE: EtvCurrencyObjType = {
  currencyFrom: 'USD',
  currencyTo: 'GBP',
  amount: '10,000 - 25,000'
};
export const PROPERTY_PAY_CURRENCY_CODE_BASED_ETV_VALUE_MAPPING: { [key in PropertyPayCurrenciesTypes as string]: string } = {
  CHF: '100,000 - 250,000',
  GBP: '100,000 - 250,000',
  USD: '100,000 - 250,000',
  JOD: '100,000 - 250,000',
  BHD: '50,000 - 100,000',
  KWD: '50,000 - 100,000',
  OMR: '50,000 - 100,000'
};
export const PROPERTY_PAY_CURRENCY_ETV_DEFAULT_VALUE: EtvCurrencyObjType = {
  currencyFrom: 'GBP',
  currencyTo: 'EUR',
  amount: 'over 250,000'
};
export const DOC_UPLOAD_COMPLETE_PAGE_URL: string = '/doc-upload-complete';
export const DOCUMENT_UPLOADED_PAGE_URL: string = '/document-uploaded';
export const DOCUMENT_UPLOADING_PAGE_URL: string = '/document-uploading';
export const REGISTRATION_PENDING_PAGE_URL: string = '/registration-pending';
export const SKIP_DOCS_PAGE_URL: string = '/skip-docs';
export const KYC_PASS_PAGE_URL: string = '/kyc-pass';
export const ADD_REGISTRATION_API_FAIL_PAGE_URL: string = '/account-error';
export const COOKIE_NAME_TO_DATA_KEY_MAPPING: Array<CookieDataObjectType> = [
  {
    cookieName: 'afflno',
    cookieDataKey: 'afflNoCookie',
    applicableAPIs: ['addEnquiryLead', 'addRegistrationLead'],
    type: 'cookiesData'
  },
  {
    cookieName: 'assetid',
    cookieDataKey: 'assetIdCookie',
    applicableAPIs: ['addEnquiryLead', 'addRegistrationLead'],
    type: 'cookiesData'
  },
  {
    cookieName: 'AFFILIATE',
    cookieDataKey: 'affiliateCookie',
    applicableAPIs: ['addEnquiryLead', 'addRegistrationLead'],
    type: 'cookiesData'
  },
  {
    cookieName: 'refercode',
    cookieDataKey: 'referCodeCookie',
    applicableAPIs: ['addEnquiryLead', 'addRegistrationLead'],
    type: 'cookiesData'
  },
  {
    cookieName: 'advert',
    cookieDataKey: 'advertCookie',
    applicableAPIs: ['addEnquiryLead', 'addRegistrationLead'],
    type: 'cookiesData'
  },
  {
    cookieName: 'click_id',
    cookieDataKey: 'seperiaClickId',
    applicableAPIs: ['addRegistrationLead'],
    type: 'cookiesData'
  },
  {
    cookieName: 'sfle',
    cookieDataKey: 'sfleCookie',
    applicableAPIs: ['addEnquiryLead', 'addRegistrationLead'],
    type: 'cookiesData'
  },
  {
    cookieName: 'ltd',
    cookieDataKey: 'leadTypeDetail',
    applicableAPIs: ['addEnquiryLead', 'addRegistrationLead'],
    type: 'ppcData'
  },
  {
    cookieName: 'aff_own_client_id',
    cookieDataKey: 'addOwnClientId',
    applicableAPIs: ['addEnquiryLead', 'addRegistrationLead'],
    type: 'ppcData'
  },
  {
    cookieName: 'click_id',
    cookieDataKey: 'trackingId',
    applicableAPIs: ['addEnquiryLead', 'addRegistrationLead'],
    type: 'ppcData'
  },
  {
    cookieName: 'refererName',
    cookieDataKey: 'refererName',
    applicableAPIs: [],
    type: 'referralCookieData'
  },
  {
    cookieName: 'referralID',
    cookieDataKey: 'referralID',
    applicableAPIs: ['addEnquiryLead'],
    type: 'referralCookieData'
  },
  {
    cookieName: 'branchURL',
    cookieDataKey: 'branchURL',
    applicableAPIs: ['addEnquiryLead'],
    type: 'referralCookieData'
  },
  {
    cookieName: 'source',
    cookieDataKey: 'source',
    applicableAPIs: ['addEnquiryLead'],
    type: 'referralCookieData'
  },
  {
    cookieName: 'subSource',
    cookieDataKey: 'subSource',
    applicableAPIs: ['addEnquiryLead'],
    type: 'referralCookieData'
  }
];
export const CONFETTI_COLOURS: HexColour[] = [
  '#26ccff',
  '#a25afd',
  '#ff5e7e',
  '#88ff5a',
  '#fcff42',
  '#ffa62d',
  '#ff36ff'
];
export const COUNTRY_ID_WITH_STATE_DROPDOWN: {
  [key in CountryCodesWithStateDropdown]: number;
} = {
  usa: 2,
  aus: 5,
  bra: 8,
  can: 10,
  mex: 150,
  nzl: 165
};
export const USA: string = 'usa';
export const ESP: string = 'esp';
export const CAN: string = 'can';
export const ZAR: string = 'ZAR';
export const ZAF: string = 'ZAF';
export const EXTRA_FIELD_COUNTRIES: { [key in CountryCodeWithAdditionalFields]: boolean } = {
  usa: true,
  esp: true
};
export const LICENSE_STATUS_COUNTRIES: string[] = [USA, CAN];
export const AUTH_SERVICE_MAX_ATTEMPTS: number = 3;
export const CD_IOS_APP_STORE_URL: string = 'https://app.currenciesdirect.com/ios';
export const CD_ANDROID_PLAY_STORE_URL: string = 'https://app.currenciesdirect.com/android';
export const HELP_HEADER: string = 'help-header';
export const WIDGET_WIDTH: string = '275px';
export const WIDGET_HEIGHT: string = '483px';
export const OPEN: string = 'open';
export const ENTER_KEY: string = 'Enter';
export const MIN_AGE: number = 18;
export const MAX_AGE: number = 110;
export const COLOUR_TEXT_BLACK: HexColour = '#000000';
export const LOGIN: string = 'Login';
export const RESET_PASSWORD: string = 'Reset Password';
export const CD_CONTACT_US_URL: string = 'https://www.currenciesdirect.com/en/let-s-talk/contact-us';
export const ONFIDO_LOCALE_LANG_CODE_MAPPING: { [keyName: string]: SupportedLanguages } = {
  'en-gb': 'en_GB',
  'fr-fr': 'fr',
  'no-no': 'no',
  'se-se': 'sv',
  'en-us': 'en_US'
};
export const ONFIDO_LEGAL_ENTITY: Array<string> = ['CDLEU', 'TOREU'];
export const ONFIDO_COUNTRY_LIST: Array<string> = ['GBR', 'IRL'];
export const USER_IDLE_DURATION_IN_MS: number = 540000;
export const SESSION_EXPIRY_MODAL_TIMER_IN_MS: number = 60000;
export const FROM_LANDING_PAGE_COOKIE_NAME: string = 'fromLandingPage';
export const LANGUAGE_COOKIE_NAME: string = 'language';
export const SESSION_EXPIRED_LS_KEY: string = 'session-expired'; // key for local storage to track session expiry
export const SESSION_TIMER_MODAL_LS_KEY: string = 'session-timer-modal'; // key for local storage to explicitly show the session timer modal
export const CUSTOM_ERROR_KEY_ARRAY: Array<keyof typeof CustomErrorTypes> = (
  Object.keys(CustomErrorTypes) as Array<keyof typeof CustomErrorTypes>
).map((keys) => keys);
export const ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG: Array<OrganizationDomainCodeConfigType> = [
  {
    organizationSearchValue: 'currenciesdirect',
    organizationCode: 'currenciesdirect'
  },
  {
    organizationSearchValue: 'torfx',
    organizationCode: 'torfx'
  },
  {
    organizationSearchValue: 'torfx.com.au',
    organizationCode: 'torfxoz'
  },
  {
    organizationSearchValue: 'fcg',
    organizationCode: 'fcg'
  },
  {
    organizationSearchValue: 'propertypay',
    organizationCode: 'propertypay'
  }
];
export const ORGANIZATION_CODE_MAPPING: { [K in OrganizationCodeTypes]: string } = {
  currenciesdirect: 'currenciesdirect',
  torfx: 'torfx',
  torfxoz: 'torfxoz',
  fcg: 'fcg',
  ramsdens: 'ramsdens',
  hl: 'hl',
  propertypay: 'propertypay'
};
export const IDENTITY_PAGE_NAVIGATION_INTERVAL: number = 3000;

export const TORFX_TNC_LINK: string = 'https://www.torfx.com/content/terms.php';
export const TORFX_PRIVACY_POLICY_LINK: string = 'https://www.torfx.com/content/privacy.php';
export const TORFX_COOKIES_LINK: string = 'https://www.torfx.com/content/cookie-policy.php';
export const TORFX_REGULATORY_INFORMATION_LINK: string =
  'https://online.torfx.com/CustomerPortal/regulatoryInformation.htm';
export const TORFXOZ_TNC_LINK: string = 'https://online.torfx.com.au/CustomerPortal/termsAndCondition.htm';
export const TORFXOZ_PRIVACY_POLICY_LINK: string = 'https://online.torfx.com.au/CustomerPortal/privacyPolicy.htm';
export const TORFXOZ_COOKIES_LINK: string = 'https://www.torfx.com.au/content/cookie-policy.php';
export const TORFXOZ_REGULATORY_INFORMATION_LINK: string =
  'https://online.torfx.com.au/CustomerPortal/regulatoryInformation.htm';
export const TORFX_IOS_APP_STORE_URL: string = 'https://app.torfx.com/ios';
export const TORFX_ANDROID_PLAY_STORE_URL: string = 'https://app.torfx.com/android';
export const TORFXOZ_IOS_APP_STORE_URL: string = 'https://app.torfx.com.au/ios';
export const TORFXOZ_ANDROID_PLAY_STORE_URL: string = 'https://app.torfx.com.au/android';
export const SHOW_ZAR_MODAL_ORGANIZATIONS_LIST: OrganizationCodeTypes[] = ['currenciesdirect'];
export const SHOW_LANGUAGE_DROPDOWN_ORGANIZATIONS_LIST: OrganizationCodeTypes[] = ['currenciesdirect', 'propertypay'];
export const LIVE_CHAT_AVAILABILITY_ORGANIZATIONS_LIST: OrganizationCodeTypes[] = ['currenciesdirect', 'torfxoz'];
export const LIVE_CHAT_TIME_TEXT_AVAILABILITY_ORGANIZATIONS_LIST: OrganizationCodeTypes[] = ['torfxoz'];
export const HELP_SECTION_WO_LIVECHAT_ITEMS_AVAILABILITY_ORGANISATION_LIST: Array<OrganizationCodeTypes> = ['torfx'];
export const DEFAULT_LIVE_CHAT_GUEST_NAME: string = 'Guest';
export const LIST_ROW_ADDITIONAL_GAP: number = 8;
export const LIST_ROW_DEFAULT_HEIGHT: number = 56;
export const DOC_UPLOAD_COMPLETE_RETURN_PAGE_URL: string = '/doc-upload-complete-return';
export const DOCUMENT_UPLOADED_RETURN_PAGE_URL: string = '/document-uploaded-return';
export const DOCUMENT_UPLOADING_RETURN_PAGE_URL: string = '/document-uploading-return';
export const REGISTRATION_PENDING_RETURN_PAGE_URL: string = '/registration-pending-return';
export const SKIP_DOCS_RETURN_PAGE_URL: string = '/skip-docs-return';
export const REGISTRATION_RETURN_KYC_FAIL_PAGE: string = '/registration/document-upload-return';
export const DOC_UPLOAD_COMPLETE_RETURN_INACTIVE_PAGE_URL: string = '/doc-upload-complete-return-inactive';
export const DOCUMENT_UPLOADED_RETURN_INACTIVE_PAGE_URL: string = '/document-uploaded-return-inactive';
export const DOCUMENT_UPLOADING_RETURN_INACTIVE_PAGE_URL: string = '/document-uploading-return-inactive';
export const REGISTRATION_PENDING_RETURN_INACTIVE_PAGE_URL: string = '/registration-pending-return-inactive';
export const REGISTRATION_RETURN_INACTIVE_KYC_FAIL_PAGE: string = '/registration/document-upload-return-inactive';
export const REGISTRATION_RETURN_INACTIVE_GENERIC_ERROR_PAGE: string = '/generic-error-return-inactive';
export const REGISTRATION_RETURN_TOKEN_QUERY_KEY: string = 'emailToken';
export const REGISTRATION_RETURN_INACTIVE_TOKEN_QUERY_KEY: string = 'token';
export const REGISTRATION_RETURN_CURRENT_LANGUAGE_QUERY_KEY: string = 'lang';
export const TORFX_CONTACT_US_URL: string = 'https://www.torfx.com/content/contact-us.php';
export const TORFXOZ_CONTACT_US_URL: string = 'https://www.torfx.com.au/content/contact-us.php';
export const LANDING_PAGE_SPECIFIC_CHANGES_ORGANIZATIONS_LIST: Array<OrganizationCodeTypes> = ['torfx', 'torfxoz', 'propertypay'];
export const INACTIVE_DASHBOARD_SPECIFIC_CHANGES_ORGANIZATIONS_LIST: Array<OrganizationCodeTypes> = ['currenciesdirect'];
export const ORGANIZATION_STATE_DROPDOWN_MAPPING: { [K in OrganizationCodeTypes]: Array<string> } = {
  currenciesdirect: [USA, CAN],
  torfx: [USA],
  torfxoz: [USA],
  fcg: [USA],
  ramsdens: [USA],
  hl: [USA],
  propertypay: [USA, CAN]
};
export const ORGANIZATION_STATE_DROPDOWN_MAPPING_CFSB_FOR_LANDING: { [K in OrganizationCodeTypes]: Array<string> } = {
  currenciesdirect: [CAN],
  torfx: [],
  torfxoz: [],
  fcg: [USA],
  ramsdens: [USA],
  hl: [USA],
  propertypay: [CAN]
};
export const ORGANIZATION_STATE_DROPDOWN_MAPPING_CFSB_FOR_DETAILS: { [K in OrganizationCodeTypes]: Array<string> } = {
  currenciesdirect: [CAN],
  torfx: [USA, CAN],
  torfxoz: [USA, CAN],
  fcg: [USA],
  ramsdens: [USA],
  hl: [USA],
  propertypay: [CAN]
};
export const FOOTER_LINKS_CONFIG_ARRAY: FooterLinkConfigType[] = [
  {
    textKey: 'common.footer.terms',
    hrefKey: 'common.footerLinks.tncLink'
  },
  {
    textKey: 'common.footer.privacyPolicy',
    hrefKey: 'common.footerLinks.privacyPolicyLink'
  },
  {
    textKey: 'common.footer.cookies',
    hrefKey: 'common.footerLinks.cookiesLink'
  },
  {
    textKey: 'common.footer.regulatoryInfo',
    hrefKey: 'common.footerLinks.regulatoryInfoLink'
  }
];
export const ORGANIZATION_CODE_APPLICABLE_LOCALES_MAPPING: { [K in OrganizationCodeTypes]: Array<string> } = {
  currenciesdirect: ['en-gb', 'en-us', 'fr-fr', 'no-no', 'se-se'],
  torfx: ['en-gb'],
  torfxoz: ['en-gb'],
  fcg: ['en-gb'],
  ramsdens: ['en-gb'],
  hl: ['en-gb'],
  propertypay: ['en-gb', 'en-us', 'fr-fr', 'no-no', 'se-se']
};
export const LIST_OF_ROUTES_HAVING_LIVE_CHAT = ['landing-page', 'register-page', 'registration'];
export const LANDING_PAGE_ROUTES = ['landing-page', 'register-page'];
export const ROUTES_LIVE_CHAT_MAPPING: Array<RouteLiveChatConfigType> = [
  {
    route: 'landing-page',
    config: LiveChatConfig.withoutOffset
  },
  {
    route: 'register-page',
    config: LiveChatConfig.withoutOffset
  },
  {
    route: 'registration',
    config: LiveChatConfig.withOffset
  }
];
export const CFSB_INFORMATIONAL_BLURB_APPLICABLE_ORGANIZATIONS: Array<OrganizationCodeTypes> = ['currenciesdirect'];
export const CFSB_USA_NO_SERVICE_ORGANISATION_LIST: Array<OrganizationCodeTypes> = ['torfx', 'torfxoz'];
export const INFORMATIONAL_BLURB_CONTENT_ARRAY_DEFINITION: Array<InformationalBlurbContent> = [
  {
    content: 'registerPage.cfsb.informationalBlurb.part1',
    type: InformationalBlurbContentType.text
  },
  {
    content: 'registerPage.cfsb.informationalBlurb.part2',
    type: InformationalBlurbContentType.link,
    href: 'common.footerLinks.regulatoryInfoLink'
  },
  {
    content: 'registerPage.cfsb.informationalBlurb.part3',
    type: InformationalBlurbContentType.text
  }
];
export const RAF_INFORMATIONAL_BLURB_CONTENT_ARRAY_DEFINITION: Array<InformationalBlurbContent> = [
  {
    content: 'registration.createLogin.refererInformationalBlurb.content',
    type: InformationalBlurbContentType.text
  },
  {
    content: 'registration.createLogin.refererInformationalBlurb.link',
    type: InformationalBlurbContentType.link,
    href: 'common.referAFriend.tncLink'
  }
];
export const BRANCH_IO_REFERRAL_COOKIE_EXPIRY_IN_DAYS: number = 14;
export const BRANCH_IO_DEEP_LINK_DATA_KEYS: { [key: string]: string } = {
  $cd_referer_name: 'refererName',
  $cd_referral_id: 'referralID',
  '~referring_link': 'branchURL',
  $cd_source: 'source',
  $cd_sub_source: 'subSource'
};
export const CFSB_BETA_TESTING_OVERRIDE_COOKIE_NAME: string = 'testingOverrideCFSB';
export const LANGUAGE_COOKIE_OPTION: CookieAttributes = { path: '/', sameSite: 'none', secure: true };
export const AFFILIATE_ASSETID_COOKIES: { [key: string]: string } = {
  afflNoCookieName: 'afflno',
  assetIdCookieName: 'assetid'
};
export const AFFILIATE_ASSETID_COOKIE_OPTION: CookieAttributes = { 
  path: '/', 
  domain: 'currenciesdirect.com',
  sameSite: 'none', 
  secure: true 
};
export const MASK_CLASS = 'cx-mask';
export const REDACT_CLASS = 'cx-redact';
export const REMOVE_BUSINESS_OPTION_IN_REGISTER_PAGE: Array<OrganizationCodeTypes> = ['propertypay'];
export const DISABLE_MOBILE_RELATED_FEATURES: Array<OrganizationCodeTypes> = ['propertypay'];
export const REMOVE_SOCIAL_PROOF_FROM_SIDEBAR: Array<OrganizationCodeTypes> = [];
export const REMOVE_REFERRER_SECTION_CONFIG: Array<OrganizationCodeTypes> = ['propertypay'];
export const ENABLE_PBU_CHECK_ORGANIZATIONS_LIST: Array<OrganizationCodeTypes> = ['propertypay'];